// popups

var contactToggles, contactPopup, effect;

contactToggles = document.querySelectorAll('.toggle-contact');
contactPopup = document.querySelector('#contact');
effect = document.querySelector('#popup-effect');

if (contactToggles) {
Array.prototype.forEach.call(contactToggles, function(el, i) {
  return el.addEventListener('click', function(e) {
  	return effect.classList.remove('hidden'), effect.classList.toggle('breath-in'), effect.classList.toggle('breath-out'),
    window.setTimeout(function () {
      return contactPopup.classList.toggle('active');
    }, 800);
  });
});
}

/////

var thisToggles, effect;

thisToggles = document.querySelectorAll('.toggle-this');
effect = document.querySelector('#popup-effect');

if (thisToggles) {
Array.prototype.forEach.call(thisToggles, function(el, i) {
  return el.addEventListener('click', function(e) {
    var teamPopup = this
    return effect.classList.remove('hidden'), effect.classList.toggle('breath-in'), effect.classList.toggle('breath-out'),
    window.setTimeout(function () {
      return teamPopup.parentNode.classList.toggle('active');
    }, 800);
  });
});
}

//////

import "./components/smooth-scroll.min";

// smooth-scroll
var scroll = new SmoothScroll('a[href*="#"]', {
  offset: 0
} );
